
import { Component, OnInit, Inject, Injector } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MetaListComponent } from '@common/core/components/meta-list.component';

import _ from 'lodash';
import { WorkspaceProviderService } from '@common/services/providers/workspace-provider.service';
import { WorkspaceCloudModel } from '@common/models/workspace/workspace-cloud.model';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';

/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

@Component({
  selector: 'infraestructure-identities-ethereum-create',
  templateUrl: './infraestructure-identities-ethereum-create.component.html',
  styleUrls: ['./infraestructure-identities-ethereum-create.component.scss']
})
export class InfraestructureIdentitiesEthereumCreateComponent extends MetaListComponent implements OnInit {
  
  userForm: FormGroup;
  errorMatcher = new CrossFieldErrorMatcher();
  public clouds: Array<WorkspaceCloudModel> = [];
  
  constructor(
    private _injector: Injector,
    public dialogRef: MatDialogRef<InfraestructureIdentitiesEthereumCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _contractSrv: WorkspaceProviderService,
    private fb: FormBuilder) 
  {
      super(_injector);
      this.initForm()
  }

  initForm() {
    this.userForm = this.fb.group({
      label: '',
      password: '',
      verifyPassword: ''
    }, {
      validator: this.passwordValidator
    })
  }

  submit() {
    this.dialogRef.close({
      label: this.userForm.get("label").value,
      password: this.userForm.get("password").value
    })
  }

  passwordValidator(form: FormGroup) {
    const condition = form.get('password').value !== form.get('verifyPassword').value;

    return condition ? { passwordsDoNotMatch: true} : null;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
