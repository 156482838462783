import { Component, OnInit, OnChanges, Injector, SimpleChanges, Input } from '@angular/core';
import { MetaListComponent } from '@common/core/components/meta-list.component';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '@common/services/shared/session.service';

import { WorkspaceMemberModel } from '@common/models/workspace/workspace-member.model';
import { WorkspaceModel } from '@common/models/workspace/workspace.model';
import { UserModel } from '@common/models/user/user.model';
import { RoleModel } from '@common/models/role/role.model';

import _ from 'lodash';


/**
 * Show the list of users participating in the workspace
 */
@Component({
  selector: 'app-workspace-member-table',
  templateUrl: './workspace-member-table.component.html',
  styleUrls: ['./workspace-member-table.component.scss']
})
export class WorkspaceMemberTableComponent extends MetaListComponent implements OnInit, OnChanges {
  @Input() user: UserModel = new UserModel();
  @Input() workspaceList: Array<WorkspaceModel> = [];
  @Input() highlightWorkspace: WorkspaceModel = new WorkspaceModel();
  @Input() hidePageSize = false;
  /** constructor */
  constructor(private _injector: Injector,
              private _sessionSrv: SessionService,
              private _translateSrv: TranslateService) {
    super(_injector);

    this.setMiniSize();
    this.table.clientSide = true;
    this.table.columns = ['name', 'description', 'role'];
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!_.isNil(_.get(changes, 'workspaceList.currentValue'))) {
      this.setDataTable(this.workspaceList);
    }

    if (_.has(changes, 'highlightWorkspace') && _.isNil(changes.highlightWorkspace.currentValue)) {
      this.highlightWorkspace = new WorkspaceModel();
    }
  }


  /********************** PUBLIC METHODS *********************/
  showRoleName(workspace: WorkspaceModel): string {
    if (!(this.user instanceof UserModel && this.user.hasValidId())) {
      return null;
    }

    if (_.get(workspace, 'owner._id') === this.user._id) {
      return this._translateSrv.instant('Workspace.User.OwnerLabel');
    }

    const member = <WorkspaceMemberModel>_.find(workspace.members || [], (m: WorkspaceMemberModel) => {
      return _.get(m, 'user._id') === this.user._id;
    });

    if (_.isNil(member)) {
      return null;
    }


    return _.get(this._sessionSrv.getRoleById(_.get(member, 'role._id')), 'name', null);
  }

}
