import { Injectable, Injector } from '@angular/core';
import { AbstractBlockchainProviderService } from '@common/blockchains/shared/abstract-blockchain.provider.service';

import _ from 'lodash';

/**
 * NOTE: to inject services by string is required provide it in app.module (see examples)
 */

@Injectable({
  providedIn: 'root'
})
export class BlockchainFactoryProviderService {
  /** constructor */
  constructor(private _injector: Injector) { }

  getBlockchainProvider(type: string): AbstractBlockchainProviderService {
    if (_.isNil(type)) {
      return null;
    }

    return this._injector.get(_.capitalize(type) + 'BlockchainProviderService');
  }
}
