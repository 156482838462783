import NavigatedViewer from 'bpmn-js/lib/NavigatedViewer';
import TouchModule from 'diagram-js/lib/navigation/touch';
import ModelingModule from 'bpmn-js/lib/features/modeling';
import inherits from 'inherits';
import { Observable } from 'rxjs';

import _ from 'lodash';



/**
 * A viewer that includes mouse navigation facilities
 *
 * @param {Object} options
 */
export function BLMNavigatedViewer(options) {
  NavigatedViewer.call(this, options);
}

inherits(BLMNavigatedViewer, NavigatedViewer);

BLMNavigatedViewer.prototype._navigationModules = [
  TouchModule
];

BLMNavigatedViewer.prototype._modelingModules = [
    ModelingModule
];


BLMNavigatedViewer.prototype._modules = [].concat(
  BLMNavigatedViewer.prototype._modules,
  BLMNavigatedViewer.prototype._navigationModules,
  BLMNavigatedViewer.prototype._modelingModules
);



/************************************ ELEMENTS METHODS ***************************************/
BLMNavigatedViewer.prototype.getRootElement = function() {
  return this.get('canvas').getRootElement();
};


BLMNavigatedViewer.prototype.getElements = function() {
  return this.get('canvas').getRootElement().children;
};


/**
 * @param {Shape | Connection} element
 * @param {object} attrs
 */
BLMNavigatedViewer.prototype.updateElement = function(element, attrs) {
  // empty method
};


/************************************ NAVIGATION METHODS ***************************************/
BLMNavigatedViewer.prototype.zoomIn = function() {
  this.get('zoomScroll').stepZoom(1);
};

BLMNavigatedViewer.prototype.zoomOut = function() {
  this.get('zoomScroll').stepZoom(-1);
};

BLMNavigatedViewer.prototype.zoomReset = function() {
  this.get('zoomScroll').reset();
};

BLMNavigatedViewer.prototype.fitViewport = function() {
  this.get('canvas').zoom('fit-viewport');
};


/************************************ EVENTBUS METHODS ***************************************/

BLMNavigatedViewer.prototype.getEventBus = function() {
  return this.get('eventBus');
};




/************************************ OBSERVABLE METHODS ***************************************/
/**
 * Returns an observable with the xml instance of diagram
 * @returns {Observable<any>}
 */
BLMNavigatedViewer.prototype.getXMLFromDiagram = function() {
  return new Observable(observer => {
    this.saveXML((errors, xml) => {
      if (errors) {
        observer.error(errors);
        return;
      }

      observer.next(xml);
      observer.complete();
    });
  });
};
