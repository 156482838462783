import { Injectable, Injector } from '@angular/core';
import { MetaProviderService } from '@common/core/services/meta-provider.service';
import { Observable, concat, throwError } from 'rxjs';
import { last, catchError } from 'rxjs/operators';

import { BusinessProcessModel } from '@common/models/business-process/business-process.model';
import { ENDPOINTS } from '@common/const/endpoint.const';
import { BPMNModel } from '@common/models/business-process/bpmn/bpmn.model';


import _ from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class BusinessProcessProviderService extends MetaProviderService {
  /** constructor */
  constructor(private _injector: Injector) {
    super(_injector);
  }


  /**
   * Return the business process catalog
   * @returns {Observable<any>}
   */
  getBusinessProcessTemplates(filter?: object): Observable<any> {
    return this.get(ENDPOINTS.BUSINESS_PROCESS.getTemplates, filter, BusinessProcessModel, true);
  }


  /**
   * Return the business process
   * @param {object} query
   * @returns {Observable<any>}
   */
  getBusinessProcess(query?: object): Observable<any> {
    return this.get(ENDPOINTS.BUSINESS_PROCESS.get, query, BusinessProcessModel, true);
  }



  /**
   * Return the business process for an user
   * @param {string} bpId
   * @returns {Observable<any>}
   */
  getBusinessProcessById(bpId: string): Observable<any> {
    return this.getById(ENDPOINTS.BUSINESS_PROCESS.getById, bpId, null, BusinessProcessModel, true);
  }


  /**
   * Create a new business process
   * @param {object} data
   * @returns {Observable<any>}
   */
  addBusinessProcess(data: object): Observable<any> {
    return this.add(ENDPOINTS.BUSINESS_PROCESS.post, this.getQuery(data), BusinessProcessModel, true);
  }


  /**
   * Update a business process
   * @param {string} bpId
   * @param {BusinessProcessModel | object} data
   * @param {boolean} [diagramToo]
   * @returns {Observable<any>}
   */
  updateBusinessProcess(bpId: string, data: BusinessProcessModel | object, diagramToo?: boolean): Observable<any> {
    return diagramToo
      ? concat(
          // this.add(BLOCKCHAIN_ENDPOINTS.enrollAdmin, this.generateEnrollData(deployment.environment), null, true),
          this.update(ENDPOINTS.BUSINESS_PROCESS.diagram.put, bpId, this.getDiagramQuery(_.get(data, 'diagram')), BusinessProcessModel, true).pipe(
            catchError(error => throwError( _.set(error, 'error.path', 'BusinessProcess.Item.Alert.FailSaveDiagram')))
          ),
          this.update(ENDPOINTS.BUSINESS_PROCESS.put, bpId, this.getQuery(data), BusinessProcessModel, true).pipe(
            catchError(error => throwError( _.set(error, 'error.path', 'BusinessProcess.Item.Alert.FailUpdate')))
          )
        ).pipe(
            last()
        )
      : this.update(ENDPOINTS.BUSINESS_PROCESS.put, bpId, this.getQuery(data), BusinessProcessModel, true);
  }


  /**
   * Update the business process diagram
   * @param {string} bpId
   * @param {BPMNModel | object} data
   * @returns {Observable<any>}
   */
  updateBusinessProcessDiagram(bpId: string, data: BPMNModel | object): Observable<any> {
    return this.update(ENDPOINTS.BUSINESS_PROCESS.diagram.put, bpId, this.getDiagramQuery(data), BusinessProcessModel, true);
  }



  /*********************** PRIVATE METHODS ************************/
  private getQuery(bp: BusinessProcessModel | object): object {
    const keyParams = {
      name: {name: 'name', invalid: [null, '']},
      description: {name: 'description', invalid: [null, '']},
      'template._id': {name: 'template_id', invalid: [null, '', 0]},
      'owner._id': {name: 'user_id', invalid: [null, '', 0]},
      published: {name: 'published', isBoolean: true, invalid: [null, '']},
      editable: {name: 'editable', isBoolean: true, invalid: [null, '']},
      markAsTemplate: {name: 'mark_as_template', isBoolean: true, invalid: [null, '']},
      thumbnail: {name: 'thumbnail', invalid: [null, '']},
      group: {name: 'group', invalid: [null, '']}
    };

    return this.generateQuery({}, keyParams, bp);
  }


  private getDiagramQuery(diagram: BPMNModel | object): object {
    const data = diagram instanceof BPMNModel ? diagram.export() : diagram;
    const keyParams = {
      version: {name: 'version', invalid: [null, '']},
      source: {name: 'source', invalid: [null, '']},
      structs: {name: 'structs', isCollection: true,  invalid: [null, '']}
    };

    return this.generateQuery({}, keyParams, data);
  }
}
