import { Injectable, Injector } from '@angular/core';
import { AbstractIdentitiesProviderService } from '@common/identities/shared/abstract-identities.provider.service';

import _ from 'lodash';

/**
 * NOTE: to inject services by string is required provide it in app.module (see examples)
 */

@Injectable({
  providedIn: 'root'
})
export class IdentitiesFactoryProviderService {
  /** constructor */
  constructor(private _injector: Injector) { }

  getIdentitiesProvider(type: string): AbstractIdentitiesProviderService {
    if (_.isNil(type)) {
      return null;
    }

    return this._injector.get(_.capitalize(type) + 'IdentitiesProviderService');
  }
}
